import { createContext } from 'react';
import cookies from './utils/cookies';

export type PartnerSiteRole = 'partner' | 'dealer';
export interface PartnerSiteUser {
  email: string;
  role: PartnerSiteRole;
}

export interface UserContext {
  user: PartnerSiteUser | null;
  login: (data: any) => void;
  logout: () => void;
  hasDealerFunctionality: boolean;
  signUpNewDealers: boolean;
  hasIframeFunctionality: boolean;
  showSupportPhone: boolean;
}

export const getUserDataFromCookies = (): PartnerSiteUser | null => {
  const email = cookies.get('email');
  return email ? { email, role: cookies.get('role') } : null;
};
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const handleLoginCookies = (data: any): void => cookies.setAll(data);
export const handleLogoutCookies = (): void => cookies.clear();

export const hasDealerFunctionality = process.env.REACT_APP_HAS_DEALER_FUNCTIONALITY === 'true';
export const signUpNewDealers =
  hasDealerFunctionality && process.env.REACT_APP_SIGN_UP_NEW_DEALERS === 'true';
export const hasIframeFunctionality =
  hasDealerFunctionality && process.env.REACT_APP_HAS_IFRAME_FUNCTIONALITY === 'true';
export const showSupportPhone = process.env.REACT_APP_SHOW_SUPPORT_PHONE === 'true';

// to reduce `if (login) login()` style boilerplate, these default values are here instead of
// making fields nullable. they are immediately overridden by userContext.Provider in AppWrapper.
const userContext = createContext<UserContext>({
  user: getUserDataFromCookies(),
  login: handleLoginCookies,
  logout: handleLogoutCookies,
  hasDealerFunctionality,
  signUpNewDealers,
  hasIframeFunctionality,
  showSupportPhone,
});

export default userContext;

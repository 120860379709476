// todo: holy moly material-table adds a lot of dependencies
// - make sure unused code is tree-shaken out
// - consider not using material-table
import MaterialTable, { Query, QueryResult } from 'material-table';
import { useEffect, useState, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';
import { Button } from 'components/_styled';
import api from 'utils/api';
import FilterSelect from 'components/materialTable/FilterSelect';
import { Theme } from 'shared/_types';
import userContext from 'shared/userContext';
import { StatusTooltip } from 'shared/components/statusTooltip/StatusTooltip';

const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-top: 3rem;
  padding-bottom: 3rem;

  @media (min-width: ${({ theme }) => theme.breakpointMedium}) {
    width: 80rem;
  }
`;

interface Props {
  theme: Theme;
}

interface ListSubmission {
  submissionId: number;
  name: string;
  email: string;
  submissionStatus: string;
  created: string;
}

interface FetchResponse {
  submissions: ListSubmission[];
  totalCount: number;
}

interface StatusMap {
  [key: number]: string;
}

interface StatusMapObject {
  statuses: StatusMap;
  statusDescriptions: StatusMap;
}

function createStatusDescriptionTooltips({ statuses, statusDescriptions }: StatusMapObject) {
  return Object.fromEntries(
    Object.entries(statuses).map(([statusId, _val]) => {
      const status = statuses[statusId as unknown as number];
      const description = statusDescriptions[statusId as unknown as number];
      return [statusId, <StatusTooltip key={statusId} title={status} description={description} />];
    }),
  );
}

const SubmissionListView = ({ theme }: Props): React.ReactElement => {
  const history = useHistory();
  const [statusMap, setStatusMap] = useState<StatusMap>({});
  const [statusDescriptionMap, setStatusDescriptionMap] = useState<StatusMap>({});
  const { hasDealerFunctionality } = useContext(userContext);

  useEffect(() => {
    async function getStatusMap() {
      try {
        const result = await api.get<StatusMapObject>('/partners/statusesAndDescriptions');
        setStatusMap(result.statuses);
        setStatusDescriptionMap(result.statusDescriptions);
      } catch (error) {
        console.error(error);
      }
    }
    // todo - because of the shift from QBP -> unaffiliated we're going to have to check whether there's a brand PARTNER_SITE_BRAND value here before displaying it
    getStatusMap();
  }, []);

  return (
    <Wrapper>
      <h1 style={{ margin: '0 0 2rem' }}>
        {`${process.env.REACT_APP_PARTNER_SITE_BRAND || ''} TradeUP Submissions`}
      </h1>
      {hasDealerFunctionality && (
        <Link to="/submissions/add">
          <Button style={{ margin: '0 0 2rem' }}>Add New Submission</Button>
        </Link>
      )}
      <MaterialTable
        title=""
        columns={[
          {
            title: 'ID',
            field: 'submissionId',
            editable: 'never',
            filtering: false,
          },
          {
            title: 'NAME',
            field: 'name',
            editable: 'never',
            filtering: false,
          },
          {
            title: 'EMAIL',
            field: 'email',
            editable: 'never',
            filtering: false,
          },
          {
            title: '',
            field: 'submissionStatusId',
            editable: 'never',
            filtering: false,
            lookup: createStatusDescriptionTooltips({
              statuses: statusMap,
              statusDescriptions: statusDescriptionMap,
            }),
            cellStyle: {
              width: '0%',
              transform: 'translateX(35%)',
            },
          },
          {
            title: 'STATUS',
            field: 'submissionStatusId',
            editable: 'never',
            lookup: statusMap,
            filterComponent: FilterSelect,
          },
          {
            title: 'CREATED',
            field: 'created',
            editable: 'never',
            filtering: false,
            render: (rowData: ListSubmission) => new Date(rowData.created).toLocaleDateString(),
          },
        ]}
        onRowClick={(e, rowData?: ListSubmission) => {
          if (rowData) history.push(`/submissions/${rowData.submissionId}`);
        }}
        localization={{ toolbar: { searchPlaceholder: 'Name or Email' } }}
        options={{
          headerStyle: { fontFamily: theme.secondaryFont },
          rowStyle: { fontFamily: theme.primaryFont },
          searchFieldAlignment: 'left',
          filtering: true,
          pageSize: 10,
          debounceInterval: 1000,
        }}
        data={(query: Query<ListSubmission>) =>
          new Promise<QueryResult<ListSubmission>>(async (resolve, reject) => {
            try {
              const mappedFilters: any = {};
              for (const { column, value } of query.filters) {
                if (column.field) {
                  mappedFilters[column.field] = value;
                }
              }

              const params = new URLSearchParams();
              const args = {
                ...mappedFilters,
                pageSize: query.pageSize,
                offset: query.page * query.pageSize,
                sortBy: query?.orderBy?.field || null,
                sortOrder: query?.orderDirection || null,
                sellerSearch: query.search || null,
              };
              for (const a in args) {
                if (args[a] && Array.isArray(args[a])) {
                  // &foo[0]=bar&foo[1]=baz
                  args[a].forEach((val: any, idx: number) => {
                    params.append(`${a}[${idx}]`, val);
                  });
                } else if (args[a]) {
                  // &foo=bar
                  params.append(a, args[a]);
                }
              }

              const result = await api.get<FetchResponse>(
                `/partners/submissions?${params.toString()}`,
              );

              resolve({
                data: result.submissions,
                page: query.page,
                totalCount: result.totalCount,
              });
            } catch (error) {
              reject(error);
            }
          })
        }
      />
    </Wrapper>
  );
};

export default withTheme(SubmissionListView);
